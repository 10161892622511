<template>
	<ButtonGeneric
		class="h-10 md:h-14 w-28 xs:w-36 sm:w-48 nextBtnColors"
		:disabled="isPlacingOrder"
		@click="disableIfPlacingOrder">
		{{ nextBtnText }}&nbsp;
		<IconsFontAwesome icon="fa-solid fa-arrow-right" />
	</ButtonGeneric>
</template>

<script setup>
	import { useI18n } from "vue-i18n"
	import { useRoute } from "vue-router"
	import { useNuxtApp } from "#app"
	import { ENABLE_PLACE_ORDER } from "assets/events/checkout.js"
	import { onMounted, onUnmounted } from "vue"

	const { $eventBus } = useNuxtApp()
	const { t } = useI18n()
	const nextBtnText = ref(t("next"))
	const route = useRoute()
	const isPlacingOrder = ref(false)

	if (route.path.includes("checkout")) {
		nextBtnText.value = t("placeOrder")
	}

	const disableIfPlacingOrder = () => {
		if (route.path.includes("checkout")) {
			isPlacingOrder.value = true
		}
	}

	onMounted(() => {
		$eventBus.$on(ENABLE_PLACE_ORDER, () => {
			isPlacingOrder.value = false
		})
	})

	onUnmounted(() => {
		$eventBus.$off(ENABLE_PLACE_ORDER)
	})
</script>

<style lang="scss" scoped>
	.nextBtnColors {
		@apply bg-footer-btn-next-bg text-footer-btn-next-text;
		@apply hover:bg-footer-btn-next-hoverBg hover:text-footer-btn-next-hoverText;
		@apply border-2 border-footer-btn-next-border;
	}
</style>
