<template>
	<div class="relative pb-1 z-30">
		<span
			id="currencySelector"
			class="font-sans text-sm font-light cursor-pointer hover:text-secondary hoverEffects"
			@click="toggleOptions"
			>{{ currency.code }}</span
		>

		<!-- Dropdown menu -->
		<div
			class="dropdownMenu top-8"
			:class="{ hidden: !showOptions }"
			@mouseleave="toggleOptions(false)"
			ref="dropdownRef">
			<ul class="dropdownMenuList">
				<li v-for="option in options">
					<p
						class="dropdownMenuListItem flex justify-between items-center gap-1 w-24"
						@click="selected(option)">
						<img
							class="h-4 inline"
							loading="lazy"
							:src="option.flag" />
						{{ option.code }}
					</p>
				</li>
			</ul>
		</div>
	</div>
</template>

<script setup>
	import { useCurrencyStore } from "~/stores/CurrencyStore"
	import { storeToRefs } from "pinia"
	import { useNuxtApp } from "#app"
	import { useCartStore } from "~/stores/CartStore"
	import useDetectOutsideClick from "~/assets/helpers/useDetectOutsideClick.js"
	import { onMounted, onUnmounted } from "vue"
	import { OPEN_CURRENCY_SELECTOR } from "assets/events/checkout.js"

	const { $cartService, $currency, $eventBus } = useNuxtApp()
	const currencyStore = useCurrencyStore()
	const { currency } = storeToRefs(currencyStore)
	const options = $currency.$getSupportedCurrencies()
	const showOptions = ref(false)
	const cartStore = useCartStore()

	const dropdownRef = ref(null)
	//Get the outside click listener for the mini cart popup
	const listener = useDetectOutsideClick(dropdownRef, ["currencySelector"], () => toggleOptions(false))

	const toggleOptions = (show = true) => {
		showOptions.value = show

		if (show) {
			//Bind the outside click listener any window event
			window.addEventListener("click", listener)
			window.addEventListener("touchend", listener)
		} else {
			window.removeEventListener("click", listener)
			window.removeEventListener("touchend", listener)
		}
	}

	const selected = (currencyOption) => {
		toggleOptions(false)
		$cartService.$updateCartCurrency(currencyOption.code)
	}

	onMounted(() => {
		$eventBus.$on(OPEN_CURRENCY_SELECTOR, () => {
			setTimeout(() => {
				toggleOptions(true)
			}, 50)
		})
	})

	onUnmounted(() => {
		$eventBus.$off(OPEN_CURRENCY_SELECTOR)
	})
</script>

<style lang="scss" scoped></style>
