<template>
	<ButtonGeneric class="h-10 md:h-14 w-28 xs:w-36 sm:w-48 backBtnColors">
		<IconsFontAwesome icon="fa-solid fa-arrow-left" />&nbsp;{{ $t("back") }}
	</ButtonGeneric>
</template>

<script setup></script>

<style lang="scss" scoped>
	.backBtnColors {
		@apply bg-footer-btn-back-bg text-footer-btn-back-text;
		@apply hover:bg-footer-btn-back-hoverBg hover:text-footer-btn-back-hoverText;
		@apply border-2 border-footer-btn-back-border;
	}
</style>
