<template>
	<div
		v-if="showSiteNotification"
		class="bg-orange-400 text-white text-center font-bold py-5 px-2 customSiteNotification"
		v-html="siteNotificationMsg"></div>
</template>

<script setup>
	const runtimeConfig = useRuntimeConfig()
	const { $messages } = useNuxtApp()
	const showSiteNotification = runtimeConfig.public.SHOW_SITE_NOTIFICATION
	const siteNotificationMsg = $messages.$t("siteNotificationMsg")
</script>

<style scoped></style>
