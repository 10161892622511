<template>
	<div class="flex justify-between gap-2 miniCartLineItemComponent">
		<div class="flex gap-3 grow">
			<!-- Nuxt Img component not working once deployed - IPX issue -->
			<img
				v-if="showProductImages"
				class="rounded-xl h-14 w-14 object-cover object-center"
				:src="imageSrc ?? runtimeConfig.public.DEFAULT_PRODUCT_IMAGE"
				alt="product image" />
			<div class="text-left my-auto grow">
				<p class="text-xs font-bold">{{ title }}</p>
				<div class="flex">
					<p class="basis-14 text-xs font-bold">{{ currency.symbol }}{{ numberHelper.formatPrice(price) }}</p>
					<p
						v-if="isReservationEnabled"
						class="inline underline mt-1 text-xs text-secondary hover:text-primary cursor-pointer"
						@click="editReservation">
						{{ $t("edit") }}
					</p>
				</div>
			</div>
		</div>

		<div
			v-if="isRemovable"
			class="flex flex-col text-center">
			<ButtonCounter
				text-style="text-sm"
				:initial-value="quantity"
				@changed="counterChanged" />
			<p
				class="mt-2 text-secondary underline text-xs hover:text-primary cursor-pointer"
				@click="removeItem">
				{{ $t("removeItem") }}
			</p>
		</div>
	</div>
</template>

<script setup>
	import { useNuxtApp } from "#app"
	import { useCurrencyStore } from "~/stores/CurrencyStore"
	import { storeToRefs } from "pinia"
	import numberHelper from "assets/helpers/numberHelper.js"

	const { id } = defineProps({
		id: String,
		title: String,
		imageSrc: String,
		price: Number,
		quantity: Number,
		isRemovable: Boolean,
		isReservationEnabled: Boolean,
	})

	const runtimeConfig = useRuntimeConfig()
	const showProductImages = runtimeConfig.public.SHOW_PRODUCT_IMAGES
	const { $cartService, $seatSelector } = useNuxtApp()
	const currencyStore = useCurrencyStore()
	const { currency } = storeToRefs(currencyStore)

	const counterChanged = ({ value }) => $cartService.$setCartItemQty(id, value)

	const removeItem = () => $cartService.$deleteCartItem(id)

	const editReservation = () => {
		$seatSelector.$editSelection()
	}
</script>

<style scoped></style>
