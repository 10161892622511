<template>
	<span
		class="font-serif text-3xl"
		:class="{ 'pt-1': total <= 0 }">
		{{ currency.symbol }}{{ getDollarsFromTotal(total) }}
		<span
			v-if="total > 0"
			class="align-super text-sm"
			>.{{ getCentsFromTotal(total) }}</span
		>
	</span>
</template>

<script setup>
	import { useCurrencyStore } from "~/stores/CurrencyStore"
	import { useCartStore } from "~/stores/CartStore"
	import { storeToRefs } from "pinia"

	const cartStore = useCartStore()
	const { total } = storeToRefs(cartStore)

	const currencyStore = useCurrencyStore()
	const { currency } = storeToRefs(currencyStore)

	const getDollarsFromTotal = (total) => parseInt(total)

	const getCentsFromTotal = (total) => {
		const totalFloat = parseFloat(total).toFixed(2).toString()
		const totalFloatValues = totalFloat.split(".")
		return totalFloatValues[1]
	}
</script>

<style lang="scss" scoped></style>
