<template>
	<div
		v-if="cartStore.getTotalItemsCount() && cartStore.getTotalItemsCount() > 0"
		class="absolute top-0 right-0 bg-pink-500 px-[5px] rounded-full text-xs text-white itemCountIcon">
		{{ cartStore.getTotalItemsCount() }}
	</div>
</template>

<script setup>
	import { useCartStore } from "~/stores/CartStore"
	const cartStore = useCartStore()
</script>

<style scoped></style>
