<template>
	<footer
		class="fixed bottom-0 bg-footer-base border-t border-footer-border py-3 md:py-4 px-4 sm:px-7 w-full z-10 footerComponent"
		:class="{ hidden: hideFooter }"
		:key="route.path">
		<div class="flex justify-between max-w-6xl mx-auto">
			<NuxtLink
				:to="backUrl"
				v-if="!hideBackBtn">
				<FooterBackPageBtn />
			</NuxtLink>

			<NuxtLink
				v-if="nextUrl === '/order/confirmation'"
				class="ml-auto"
				@click="submitOrder">
				<FooterNextPageBtn />
			</NuxtLink>
			<NuxtLink
				v-else
				class="ml-auto"
				:to="nextUrl">
				<FooterNextPageBtn />
			</NuxtLink>
		</div>
	</footer>
</template>

<script setup>
	import { useNuxtApp } from "#app"
	import { useSettingsStore } from "~/stores/SettingsStore"
	import { CHECKOUT_SUBMIT, ENABLE_PLACE_ORDER } from "~/assets/events/checkout.js"
	import { storeToRefs } from "pinia"
	import { SHOW_ERROR } from "assets/events/errors.js"

	const runtimeConfig = useRuntimeConfig()
	const disabledCheckoutFields = runtimeConfig.public.IS_CHECKOUT_DISABLED
	const { $eventBus, $messages } = useNuxtApp()
	const settingsStore = useSettingsStore()
	const { showRestrictedCheckoutPage } = storeToRefs(settingsStore)

	//Use NuxtLink components to utilize Nuxt page prefetching instead of programmatic routing
	//Uses the key attribute on the root element (footer) to force a component refresh when url changes. Otherwise, the urls do not get updated.
	const route = useRoute()
	const allPages = runtimeConfig.public.PAGES

	let hideBackBtn = ref(false)
	let hideFooter = ref(false)
	let backUrl = ref("")
	let nextUrl = ref("")

	//Determine if the page will hide the back/next buttons
	//Watching for a route change is necessary to recompute variables, otherwise the setup is only run once
	watchEffect(() => {
		let path = route.path

		const pageIndex = allPages.findIndex((page) => page.urlPath === path)
		if (pageIndex === -1) {
			//If page has not been configured
			hideBackBtn.value = false
			hideFooter.value = true
			backUrl.value = ""
			nextUrl.value = ""
		} else if (pageIndex === 0) {
			//If viewing the first page
			hideBackBtn.value = true
			hideFooter.value = false
			backUrl.value = ""
			nextUrl.value = allPages[pageIndex + 1].urlPath
			settingsStore.setShowRestrictedCheckoutPage(false)
		} else if (pageIndex === allPages.length - 1) {
			//If viewing the last page (confirmation page)
			hideBackBtn.value = false
			hideFooter.value = true
			backUrl.value = ""
			nextUrl.value = ""
			settingsStore.setShowRestrictedCheckoutPage(false)
		} else {
			//If viewing any other configured page

			if (path !== "/order/checkout") {
				//Reset the checkout page restriction if viewing another configured page
				settingsStore.setShowRestrictedCheckoutPage(false)
			}

			//ie: showRestrictedCheckoutPage is modified when viewing volunteer crew deposits
			hideBackBtn.value = showRestrictedCheckoutPage.value
			hideFooter.value = false
			backUrl.value = allPages[pageIndex - 1].urlPath
			nextUrl.value = allPages[pageIndex + 1].urlPath
		}
	})

	//If on the checkout page, then start the checkout process located on the checkout.vue page
	const submitOrder = () => {
		if (disabledCheckoutFields) {
			$eventBus.$emit(SHOW_ERROR, {
				titleText: $messages.$t("maintenance1"),
				bodyText: $messages.$t("maintenance2"),
			})
			$eventBus.$emit(ENABLE_PLACE_ORDER)
		} else {
			$eventBus.$emit(CHECKOUT_SUBMIT)
		}
	}
</script>

<style scoped></style>
