<template>
	<div class="pb-3 xPadding flex justify-between items-center text-xs">
		<p class="font-thin text-gray-500">{{ $t("orderSummaryPrices", { currencyName: currency.namePlural }) }}</p>
		<p
			class="font-bold h-4 flex justify-end items-center gap-1 w-18 cursor-pointer"
			@click="clickedCurrency">
			<ClientOnly>
				<img
					class="h-full inline"
					loading="lazy"
					:src="currency.flag" />
				{{ currency.symbol + currency.code }}
			</ClientOnly>
		</p>
	</div>
</template>

<script setup>
	import { useCurrencyStore } from "~/stores/CurrencyStore"
	import { storeToRefs } from "pinia"

	const currencyStore = useCurrencyStore()
	const { currency } = storeToRefs(currencyStore)

	const emits = defineEmits(["clickedCurrency"])

	const clickedCurrency = () => {
		emits("clickedCurrency")
	}
</script>

<style lang="scss" scoped></style>
