<template>
	<div class="text-primary flex justify-end w-80 mt-2 cartContainerComponent">
		<div class="flex flex-col gap-0">
			<div class="flex justify-end items-center">
				<HeaderCartTotal />
				&nbsp;
				<HeaderCurrencySelector />
			</div>
			<HeaderCartTimeoutTimer />
		</div>
		<HeaderCartDivider />
		<HeaderMiniCart />
	</div>
</template>

<script setup></script>

<style lang="scss" scoped></style>
