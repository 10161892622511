<template>
	<div class="relative my-auto miniCartComponent">
		<div>
			<IconsFontAwesome
				id="miniCartIcon"
				icon="fa-solid fa-shopping-cart"
				class="hoverEffects"
				:class="{ 'cursor-pointer hover:text-secondary': !disableMiniCart }"
				@click="toggleMiniCart" />
			<HeaderCartItemCount />
		</div>

		<div
			v-if="showCart"
			class="rounded-lg w-screen md:w-96 absolute top-8 max-sm:mr-[-1em] right-0 z-20 text-black bg-gray-100 miniCartPopupComponent"
			ref="miniCartRef">
			<div class="h-20 border-b flex justify-between xPadding pt-4 md:pt-5 shadow-md bg-white rounded-t-md">
				<div>
					<div>
						<p class="font-serif text-xl text-secondary/80 inline">{{ $t("miniCartTitle") }}</p>
						<p
							class="inline underline ml-2 text-xs text-gray-400 hover:text-secondary cursor-pointer"
							@click="clearCart">
							{{ $t("clearCart") }}
						</p>
					</div>
					<p class="text-sm text-gray-500 font-thin">{{ $t("miniCartSubtitle") }}</p>
				</div>
				<div class="text-gray-400">
					<IconsFontAwesome
						icon="fa-solid fa-xmark"
						class="hover:cursor-pointer"
						@click="toggleMiniCart" />
				</div>
			</div>

			<div class="bg-gray-100 rounded-b-xl mt-3">
				<HeaderMiniCartCurrencyRow @clickedCurrency="changeCurrency" />

				<CardDivider color="border-gray" />

				<div class="xPadding pt-4 md:pt-5 pb-5 flex flex-col gap-2">
					<div class="flex flex-col gap-2 max-h-56 overflow-auto pt-[1px] pr-[1px]">
						<div v-if="items.size">
							<HeaderMiniCartLineItem
								v-for="item in displayItems"
								:key="item.id + item.price + item.quantity"
								:id="item.id"
								:title="item.title"
								:price="item.price"
								:imageSrc="item.images ? item.images[0] : runtimeConfig.public.DEFAULT_PRODUCT_IMAGE"
								:quantity="item.quantity"
								:is-removable="item.isRemovable"
								:is-reservation-enabled="!!item.reservationSection"
								class="mb-2" />
						</div>
						<div
							v-else
							class="text-xs text-gray-400 place-self-center mb-4">
							{{ $t("emptyCart") }}
						</div>
					</div>

					<CardDivider color="border-gray mb-1" />

					<div>
						<div class="flex justify-between items-center">
							<p class="text-sm">{{ $t("subtotal") }}</p>
							<p>{{ currency.symbol }}{{ parseFloat(subtotal).toFixed(2) }}</p>
						</div>

						<div
							v-if="runtimeConfig.public.ENABLE_REFUNDABLE_BOOKING"
							class="flex justify-between items-center">
							<p class="text-sm">{{ $t("refundableBooking") }}</p>
							<p>{{ currency.symbol }}{{ parseFloat(refundableBookingCost).toFixed(2) }}</p>
						</div>

						<div
							v-for="[key, value] in Object.entries(fees)"
							class="flex justify-between items-center">
							<p class="text-sm">{{ key }}</p>
							<p>{{ currency.symbol }}{{ parseFloat(value).toFixed(2) }}</p>
						</div>

						<div
							v-if="discountedAmount"
							class="flex justify-between items-center">
							<p class="text-sm">{{ $t("discounts") }}</p>
							<p class="font-bold">-{{ currency.symbol }}{{ parseFloat(discountedAmount).toFixed(2) }}</p>
						</div>
					</div>

					<CardDivider color="border-gray" />

					<div class="flex justify-between text-secondary text-xl text-md font-bold priceTotal">
						<p>{{ $t("total") }}</p>
						<p>{{ currency.symbol }}{{ parseFloat(total).toFixed(2) }}</p>
					</div>

					<CardDivider color="border-gray" />

					<p
						v-if="runtimeConfig.public.ENABLE_COUPON_CODES"
						class="mt-3 text-xs text-gray-500 text-center">
						{{ $t("miniCartCoupons") }}
					</p>

					<NuxtLink
						class="text-center"
						to="/order/checkout"
						@click="toggleMiniCart">
						<ButtonSecondary class="mt-1">{{ $t("checkout") }}</ButtonSecondary>
					</NuxtLink>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
	import useDetectOutsideClick from "~/assets/helpers/useDetectOutsideClick.js"
	import { useCurrencyStore } from "~/stores/CurrencyStore"
	import { storeToRefs } from "pinia"
	import { useCartStore } from "~/stores/CartStore"
	import { useNuxtApp } from "#app"
	import { RESET_PRODUCT_CARD } from "assets/events/api.js"
	import { OPEN_CURRENCY_SELECTOR } from "assets/events/checkout.js"

	const runtimeConfig = useRuntimeConfig()
	const { $cartService, $productsService, $eventBus } = useNuxtApp()

	//Disable mini cart if on confirmation page
	const route = useRoute()
	const disableMiniCart = ref(false)
	watchEffect(() => {
		disableMiniCart.value = route.path === "/order/confirmation"
	})

	const currencyStore = useCurrencyStore()
	const { currency } = storeToRefs(currencyStore)

	const cartStore = useCartStore()
	const { items, subtotal, total, fees, refundableBookingCost, discountedAmount } = storeToRefs(cartStore)
	const displayItems = ref(cartStore.getItems())
	watch(items, () => {
		displayItems.value = cartStore.getItems()
	})

	const products = ref([])
	const showCart = ref(false)
	const miniCartRef = ref(null)

	//Get the outside click listener for the mini cart popup
	const listener = useDetectOutsideClick(miniCartRef, ["miniCartIcon"], () => toggleMiniCart())

	const toggleMiniCart = () => {
		if (!disableMiniCart.value) {
			showCart.value = !showCart.value

			//Bind the outside click listener to window events
			if (showCart.value) {
				window.addEventListener("click", listener)
				window.addEventListener("touchend", listener)
			} else {
				window.removeEventListener("click", listener)
				window.removeEventListener("touchend", listener)
			}
		}
	}

	const changeCurrency = () => {
		$eventBus.$emit(OPEN_CURRENCY_SELECTOR)
	}

	const clearCart = () => {
		$cartService.$clearCart()
		toggleMiniCart()
		$eventBus.$emit(RESET_PRODUCT_CARD)
	}
</script>

<!--Unscoped styles to apply style to minicart icon svg-->
<style lang="scss">
	#miniCartIcon svg {
		@apply h-6;
	}

	.xPadding {
		@apply px-4 md:px-6;
	}

	.itemCountIcon {
		font-size: 8pt;
		margin-top: -10px;
		margin-right: -6px;
	}
</style>
