<template>
	<div
		v-if="isLoading"
		class="fixed flex items-center justify-center z-50 w-screen h-screen bg-loader-bg">
		<div class="rounded-lg mx-auto loadingPopupComponent">
			<img
				:src="runtimeConfig.public.DEFAULT_LOADER"
				alt="Loading..." />
		</div>
	</div>
</template>

<script setup>
	const runtimeConfig = useRuntimeConfig()

	let isLoading = ref(true)
	onMounted(async () => {
		await nextTick()

		// Use requestAnimationFrame to wait for the next paint
		requestAnimationFrame(() => {
			// Use a small timeout to allow any async operations to complete
			setTimeout(() => {
				isLoading.value = false
			}, 500)
		})
	})
</script>

<style scoped></style>
