<template>
	<p
		v-if="expiresAt"
		class="text-sm font-thin mt">
		{{ $t("cartTimeoutCountdown", { time: timeRemainingDisplay }) }}
	</p>
</template>

<script setup>
	import { useCartStore } from "~/stores/CartStore"
	import { storeToRefs } from "pinia"

	const cartStore = useCartStore()
	const { expiresAt } = storeToRefs(cartStore)

	const timeRemainingDisplay = ref("")
	let intervalId = null

	const updateDisplayTime = () => {
		if (expiresAt.value) {
			const currentTime = new Date().getTime()
			const futureTime = expiresAt.value.getTime()
			const timeRemaining = futureTime - currentTime
			timeRemainingDisplay.value = formatDurationToMMSS(timeRemaining)
		} else {
			timeRemainingDisplay.value = ""
		}
	}

	const formatDurationToMMSS = (duration) => {
		const minutes = Math.floor(duration / 60000) // Divide by 60,000 to get minutes
		const seconds = Math.floor((duration % 60000) / 1000) // Get the remainder and divide by 1,000 to get seconds
		return `${minutes}:${seconds.toString().padStart(2, "0")}`
	}

	//When the cartStore has expiresAt set, add/remove the updateTime interrupt
	watch(expiresAt, (value) => {
		if (value) {
			updateDisplayTime()
			intervalId = setInterval(updateDisplayTime, 1000)
		} else {
			clearInterval(intervalId)
		}
	})

	onMounted(() => {
		nextTick(() => {
			updateDisplayTime()
		})
	})
</script>

<style lang="scss" scoped>
	.text-xxs {
		font-size: 8pt;
	}

	.mt {
		margin-top: -5px;
	}
</style>
