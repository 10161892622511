<template>
	<header>
		<nav class="md:container mx-auto p-4 h-20 flex justify-between items-center">
			<HeaderBackToSite v-if="showShoppingHeader" />
			<HeaderLogo :class="{ 'mx-auto': !showShoppingHeader, '!block': !showShoppingHeader }" />
			<HeaderCartContainer v-if="showShoppingHeader" />
		</nav>
	</header>
</template>

<script setup>
	const appConfig = useAppConfig()
	const showShoppingHeader = !appConfig.showMaintenancePage
</script>

<style scoped></style>
