<template>
	<div
		v-if="shouldShowStagingWarning"
		class="bg-red-500 text-white text-center font-bold py-5">
		{{ $t("stagingSiteWarning") }}
	</div>
</template>

<script setup>
	const runtimeConfig = useRuntimeConfig()
	const shouldShowStagingWarning = runtimeConfig.public.SHOW_TEST_SITE_WARNING
</script>

<style scoped></style>
